<template>
    <div class="applicationItem">
        <img :src="src" class="applicationItem__icon" alt="">
        <div class="applicationItem__text">
            <div class="applicationItem__text__title">{{ title }}</div>
            <div class="applicationItem__text__slogan">{{ slogan }}</div>
        </div>
        <div class="applicationItem__circle">
            <CircleProgress v-if="isClick" :radius="14" :stroke="1" v-on:finish="handleFinish" />
        </div>
        <div :class="[(isClick && !isFinish) ? 'applicationItem__download--active' : '', 'applicationItem__download']" @click="handleClick" ref="btn">{{ isClick ? translateLang('trust') : translateLang('download') }}</div>
    </div>
</template>

<script>
import CircleProgress from '@/components/CircleProgress.vue'
import { mapGetters } from 'vuex'

export default {
    name: 'ApplicationItem',
    components: {
        CircleProgress
    },
    data() {
        return {
            isClick: false,
            isFinish: false,
            lessPingUrl: '#'
        }
    },
    props: {
        src: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        slogan: {
            type: String,
            required: true
        },
        downloadLinks: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    computed: {
        ...mapGetters(['translateLang', 'mobileconfig_url'])
    },
    created() {
        this.lessPingUrl = this.downloadLinks[0]
        this.findLessPingUrlPromise(this.downloadLinks)
            .then(url => this.lessPingUrl = url)
            .catch(err => console.log(err))
    },
    methods: {
        handleClick() {
            // 下載安裝描述檔
            if (!this.isClick) {
                this.isClick = true
                window.location.href = this.lessPingUrl
                return
            }
            // 下載跳轉至手機設定描述檔
            if (this.isClick && this.isFinish) {
                window.location.href = this.mobileconfig_url
            }
        },
        handleFinish() {
            this.isFinish = true
        },
        findLessPingUrlPromise(urls) {
            const urlPromises = urls.map(url => new Promise((resolve, reject) => {
                const maxWaitingTime = 30000
                // 超時
                setTimeout(() => reject(), maxWaitingTime)
                fetch(url, { mode: 'no-cors' })
                    .then(res => resolve(url))
                    // 無法連上網站
                    .catch(err => reject())
            }))
            return new Promise((resolve, reject) => {
                Promise.any(urlPromises)
                    .then(url => resolve(url))
                    .catch(err => reject(err))
            })
        }
    }
}
</script>

<style scoped lang="scss">
@import '@@/theme';

.applicationItem {
    width: 100%;
    height: 68px;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
    .applicationItem__icon {
        width: 68px;
        height: 68px;
        margin-right: 20px;
    }
    .applicationItem__text {
        max-width: calc(100% - 198px);
        .applicationItem__text__title {
            color: #333333;
            font-family: "PingFang TC";
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 16px;
            margin-bottom: 10px;
        }
        .applicationItem__text__slogan {
            color: #999999;
            font-family: "PingFang TC";
            font-size: 12px;
            letter-spacing: 0;
            line-height: 12px;
        }
    }
    .applicationItem__circle {
        width: 28px;
        height: 28px;
        position: absolute;
        right: 82px;
        user-select: none;
    }
    .applicationItem__download {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0;
        color: #fff;
        height: 24px;
        width: 72px;
        border-radius: 18px;
        background-color: $btn-bg-color;
        box-shadow: 2px 2px 6px 0 rgba(76,158,234,0.3);
        font-family: "PingFang TC";
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 0;
        user-select: none;
        &.applicationItem__download--active {
            background: #DDDDDD;
        }
    }
}
</style>
