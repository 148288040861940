<template>
    <div class="clientList">
        <ClientItem v-for="(url, index) in customerServiceUrl" :key="index" 
            :url="url" :title="`${translateLang('clientItem_title')} ${index + 1}`" :subTitle="translateLang('clientItem_subtitle')"
        />
    </div>
</template>

<script>
import ClientItem from '@/components/ClientItem.vue'
import { mapGetters } from 'vuex'

export default {
    name: 'ClientList',
    components: {
        ClientItem
    },
    computed: {
        ...mapGetters(['customerServiceUrl', 'translateLang'])
    },
    // created() {
    //     this.$store.dispatch('getMerchantSetting')
    // },
}
</script>

<style lang="scss">
.clientList {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    background: #fff;
    overflow: auto;
    padding: 20px 10px 0;
    flex: 1;
    align-content: flex-start;
}
</style>
