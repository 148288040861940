<template>
    <div class="application">
        <Header
            :srcImg="srcImg"
            :title="translateLang(`brand_application_title`)"
            :content="translateLang(`brand_application_content`)"
        />
        <ApplicationList />
        <Helper :text="translateLang(`brand_application_helper`)" :title_modal="translateLang('application_helper_title')" type="application" />
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import ApplicationList from '@/components/ApplicationList.vue'
import Helper from '@/components/Helper.vue'
import { mapGetters } from 'vuex'
import { getConfig, initConfig } from '../../config'

export default {
    name: 'Application',
    data(){
        return {vendorId:getConfig().VENDERID,isMoreLang:['vd003','vd005', 'vd010']}
    },
    components: {
        Header,
        ApplicationList,
        Helper
    },
    computed: {
        ...mapGetters(['translateLang', 'client', 'cdnLogoUrl','currentLang']),
        srcImg(){
          return  `${this.cdnLogoUrl}/service/${this.isMoreLang.includes(this.vendorId)?this.currentLang:''}/banner_application.png`
        }
    },
}
</script>

<style scoped lang="scss">
.application {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
}
</style>
