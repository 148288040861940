<template>
    <div class="nav">
        <router-link to="/client" class="nav__client" v-slot="{ href, isActive }">
            <a :href="href">
                <img v-show="isActive || $route.path === '/'" :src="require('@@/icon_client_active.svg')" class="nav__client__img" alt="">
                <img v-show="!isActive && $route.path !== '/'" :src="require('@@/icon_client.svg')" class="nav__client__img" alt="">
                <span class="nav__client__text" :class="[ isActive || $route.path === '/' ? 'nav__client__text--active' : '' ]">{{ translateLang(`brand_client_title`) }}</span>
            </a>
        </router-link>
        <router-link to="/application" class="nav__application" v-slot="{ href, isActive }">
            <a :href="href">
                <img v-show="isActive" :src="require('@@/icon_application_active.svg')" class="nav__application__img" alt="">
                <img v-show="!isActive" :src="require('@@/icon_application.svg')" class="nav__application__img" alt="">
                <span class="nav__application__text" :class="[ isActive ? 'nav__application__text--active' : '' ]">{{ translateLang(`brand_application_title`) }}</span>
            </a>
        </router-link>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'Nav',
    computed: {
        ...mapGetters(['translateLang', 'client'])
    }
}
</script>

<style lang="scss">
@import '@@/theme';

.nav {
    width: 100%;
    height: 83px;
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: space-evenly;
    background: #fff;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
    .nav__client {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
        text-decoration: none;
        .nav__client__img {
            width: 18px;
            height: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 6px 0 5px 0;
        }
        .nav__client__text {
            color: #666666;
            font-family: "PingFang TC";
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 12px;
        }
        .nav__client__text--active {
            color: $theme-color;
        }
    }
    .nav__application {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
        text-decoration: none;
        .nav__application__img {
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 6px 0 3px 0;
        }
        .nav__application__text {
            color: #666666;
            font-family: "PingFang TC";
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 12px;
        }
        .nav__application__text--active {
            color: $theme-color;
        }
    }
}
</style>
