<template>
    <div id="app">
        <keep-alive>
            <router-view />
        </keep-alive>
        <Nav />
        <div class="cover" v-show="isModal"></div>
    </div>
</template>

<script>

import { mapGetters } from 'vuex'
import Nav from '@/components/Nav.vue'
export default {
    components: {
        Nav
    },
    created() {
        this.$store.dispatch('getMerchantSetting')
    },
    computed: {
        ...mapGetters(['isModal']),
    }
}
</script>

<style lang="scss">
* {
    box-sizing: border-box;
}
body {
    overflow: hidden;
}
img {
    display: block;
}
#app {
    width: 100%;
    height: 100vh;
    position: relative;
    .cover {
        width: 100%;
        height: 100%;
        background: rgba(51,51,51,0.5);
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
    }
}
</style>
