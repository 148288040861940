<template>
    <div class="applicationList">
        <ApplicationItem v-for="(url, index) in applicationUrlFiltered" :key="index" 
            :src="url.src" :title="url.title" :slogan="url.slogan"
            :downloadLinks="url.downloadLinks"
        />
    </div>
</template>

<script>
import ApplicationItem from '@/components/ApplicationItem.vue'
import { mapGetters } from 'vuex'

export default {
    name: 'ApplicationList',
    components: {
        ApplicationItem
    },
    computed: {
        ...mapGetters(['translateLang', 'client', 'applicationUrl', 'cdnLogoUrl']),
        applicationUrlFiltered() {
            const urlsFiltered = []
            this.applicationUrl.forEach(item => {
                const found = this.urls.find(url => url.appType === item.appType)
                if (found) {
                    const obj = JSON.parse(JSON.stringify(found))
                    obj.downloadLinks = item.downloadLinks
                    urlsFiltered.push(obj)
                }
            })
            return urlsFiltered
        },
        urls() {
            return [
                {
                    appType: 4,
                    src: `${this.cdnLogoUrl}/square_logo.png`,
                    title: this.translateLang(`brand_applicationItem2_title`),
                    slogan: this.translateLang(`brand_applicationItem2_subtitle`)
                },
                {
                    appType: 8,
                    src: `${this.cdnLogoUrl}/square_logo.png`,
                    title: this.translateLang(`brand_applicationItem2_title`),
                    slogan: this.translateLang(`brand_applicationItem2_subtitle`)
                },
                {
                    appType: 16,
                    src: `${this.cdnLogoUrl}/square_logo.png`,
                    title: this.translateLang(`brand_applicationItem2_title`),
                    slogan: this.translateLang(`brand_applicationItem2_subtitle`)
                }
            ]
        }
    },
    created() {
        this.$store.dispatch('getApplicationUrl')
    }
}
</script>

<style scoped lang="scss">
.applicationList {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    background: #fff;
    overflow: auto;
    padding: 20px 20px calc(10px + 83px + 92px + 100px) 20px;
    flex: 1;
    align-content: flex-start;
}
</style>
