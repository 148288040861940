import { Console } from './Console'

const versionInfo = () => {
    window._versionInfo_ = {
        version: process.env.VUE_APP_VERSION,
        commitHEAD: process.env.VUE_APP_commitHEAD,
        client: process.env.VUE_APP_CLIENT
    }

    Console.info(`[CS] Config -> Using ${process.env.NODE_ENV} environment version ${process.env.VUE_APP_VERSION}`)
}

export default versionInfo