<template>
    <div class="helper">
        <div class="helper__container" @click="handleModal">
            <img src="@@/question_icon.png" class="helper__container__questionIcon" alt="">
            <span class="helper__container__text">{{ text }}</span>
            <img src="@/assets/arrow_icon.svg" class="helper__container__arrowIcon" alt="">
        </div>
        <transition name="slide">
            <div v-show="isModal" class="helper__modal">
                <div class="helper__modal__header" v-touch:swipe.bottom="handleModal">
                    <div class="helper__modal__header__cancel" @click="handleModal">{{ translateLang('cancel') }}</div>
                    <div class="helper__modal__header__bar"></div>
                    <div class="helper__modal__header__title">{{ title_modal }}</div>
                </div>
                <div class="helper__modal__imgContainer">
                    <img v-if="type === 'client'" :src="`${cdnLogoUrl}/service/${filenameLang}/helper_client.png`" class="helper__modal__imgContainer__img" alt="">
                    <div v-if="type === 'application'">
                        <img :src="`${cdnLogoUrl}/service/${filenameLang}/helper_application.png`" class="helper__modal__imgContainer__img" alt="">
                        <span @click="handleDownload" class="helper__modal__btnApplication">
                            <div>{{ translateLang('trust') }}</div>
                        </span>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { getConfig } from '../../config'
import { mapGetters, mapMutations } from 'vuex'

export default {
    name: 'Helper',
    props: {
        text: {
            type: String,
            required: true
        },
        title_modal: {
            type: String,
            required: true
        },
        type: {
            // client or application
            type: String,
            required: true
        }
    },
    methods: {
        ...mapMutations(['setIsModal']),
        handleModal() {
            this.setIsModal()
        },
        // 下載跳轉至手機設定描述檔
        handleDownload() {
            window.location.href = this.mobileconfig_url
        }
    },
    computed: {
        ...mapGetters(['translateLang', 'filenameLang', 'isModal', 'cdnLogoUrl', 'mobileconfig_url']),
    }

}
</script>

<style scoped lang="scss">
@import '@@/theme';

.helper {
        z-index: 2;
    .helper__container {
        position: fixed;
        left: 0;
        bottom: 83px;
        display: flex;
        align-items: center;
        width: calc(100% - 20px);
        height: 44px;
        margin: 0 10px 24px 10px;
        border-radius: 10px;
        padding: 10px 20px 10px 10px;
        background-color: #fff;
        box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.1);
        .helper__container__questionIcon {
            width: 24px;
            height: 24px;
            margin-right: 10px;
        }
        .helper__container__text {
            color: $theme-color;
            font-family: "PingFang TC";
            font-size: 13px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 13px;
        }
        .helper__container__arrowIcon {
            width: 20px;
            height: 20px;
            margin-left: auto;
        }
    }
    .helper__modal {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background: #FBFBFC;
        border-radius: 20px 20px 0 0;
        .helper__modal__header {
            width: 100%;
            height: 62px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            position: relative;
            font-family: "PingFang TC";
            font-size: 16px;
            font-weight: 600;
            letter-spacing: -0.41px;
            line-height: 16px;
            border-bottom: 1px solid #DDD;
            flex-direction: column;
            .helper__modal__header__bar {
                height: 5px;
                width: 36px;
                border-radius: 4px;
                background-color: #BBBBBB;
                margin-bottom: 16px;
            }
            .helper__modal__header__title {
                margin-bottom: 15px;
            }
            .helper__modal__header__cancel {
                position: absolute;
                left: 16px;
                bottom: 15px;
                color: $theme-color;
                font-family: "PingFang TC";
                font-size: 14px;
                font-weight: 600;
                letter-spacing: -0.45px;
                line-height: 14px;
            }
        }
        .helper__modal__imgContainer {
            width: 100%;
            height: calc(100% - 62px);
            overflow: auto;
            .helper__modal__imgContainer__img {
                width: 100%;
            }
        }
    }
}
.helper__modal__btnApplication {
    text-decoration: none;
    div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(100% - 50px);
        height: 36px;
        margin: 6px 25px 25px 25px;
        background: $theme-color;
        color: #FFFFFF;
        font-family: "PingFang TC";
        font-size: 14px;
        letter-spacing: 0;
        line-height: 14px;
        text-align: center;
        border-radius: 22px;
    }
}
.slide-enter-active {
    transition: all .3s ease;
}
.slide-leave-active {
    transition: all .3s ease;
}
.slide-enter, .slide-leave-to {
    transform: translateY(100%);
}
</style>
