import Vue from 'vue'
import VueRouter from 'vue-router'
import Client from '../views/Client.vue'
import Application from '../views/Application.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Client
  },
  {
    path: '/client',
    name: 'Client',
    component: Client
  },
  {
    path: '/application',
    name: 'Application',
    component: Application
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  routes
})

export default router
