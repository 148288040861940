<template>
    <div class="circleProgress">
        <svg
            class="progress-ring"
            :height="radius * 2"
            :width="radius * 2"
        >
            <circle
                stroke-width="1"
                fill="transparent"
                stroke="#DDDDDD"
                :r="radius - stroke * 2"
                :cx="radius"
                :cy="radius"
            />
            <circle
                ref="circle"
                class="progress-ring__circle"
                :stroke-width="stroke"
                fill="transparent"
                :stroke-dasharray="circumference + ' ' + circumference"
                :style="{ strokeDashoffset: strokeDashoffset }"
                :r="radius - stroke * 2"
                :cx="radius"
                :cy="radius"
            />
        </svg>
        <span class="circleProgress__text">{{ number }}%</span>
    </div>
</template>

<script>
export default {
    name: 'CircleProgress',
    props: {
        radius: {
            type: Number,
            required: true
        },
        stroke: {
            type: Number,
            required: true
        }
    },
    data() {
        const normalizedRadius = this.radius - this.stroke * 2
        const circumference = normalizedRadius * 2 * Math.PI
        const progress = 0
        const number = 0
        return {
            normalizedRadius,
            circumference,
            progress,
            number
        }
    },
    computed: {
        strokeDashoffset() {
            return this.circumference - this.progress / 100 * this.circumference
        }
    },
    methods: {

    },
    mounted() {
        const interval = setInterval(() => {
            this.progress += 1
            this.number += 1
            if (this.progress === 100) {
                clearInterval(interval)
                this.$emit('finish')
            }
        }, 600)
    }
}
</script>

<style scoped lang="scss">
@import '@@/theme';

.circleProgress {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .progress-ring__circle {
        transition: stroke-dashoffset 0.35s;
        transform: rotate(-90deg);
        transform-origin: 50% 50%;
        stroke: $btn-bg-color;
    }
    .circleProgress__text {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(0.75);
        color: #999999;
        font-family: "PingFang TC";
        font-size: 8px;
        letter-spacing: 0;
        line-height: 12px;
    }
}
</style>
