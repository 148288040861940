import './utils/setupPublicPath'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import generateStore from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import '@/assets/reset.css'
import versionInfo from '@/utils/versionInfo'
import Vue2TouchEvents from 'vue2-touch-events'

Vue.use(VueAxios, axios)
Vue.config.productionTip = false
Vue.use(Vue2TouchEvents)

const init = async () => {
  new Vue({
    router,
    store: await generateStore(),
    render: h => h(App)
  }).$mount('#app')

  versionInfo()
}

init()
Vue.prototype.$client = process.env.VUE_APP_CLIENT