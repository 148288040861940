<template>
    <div class="clientItem" @click="handleClick(url)">
        <img src='@@/icon_client_item.svg' class="clientItem__icon" alt="">
        <div style="margin-right: 72px;">
            <div class="clientItem__title">{{ title }}</div>
            <div class="clientItem__subTitle">{{ subTitle }}</div>
        </div>
        <img src="@/assets/arrow_icon.svg" class="clientItem__arrowIcon" alt="">
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'ClientItem',
    props: {
        title: {
            type: String,
            required: true
        },
        subTitle: {
            type: String,
            required: true
        },
        url: {
            type: String,
            required: true
        }
    },
    methods: {
        handleClick(url) {
            window.open(url)
        }
    },
    computed: {
        ...mapGetters(['filenameLang'])
    }
}
</script>

<style lang="scss">
.clientItem {
    width: 100%;
    height: 68px;
    margin: 0 0 10px 0;
    display: flex;
    align-items: center;
    border-radius: 10px;
    box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.1);
    background: #fff;
    padding: 15px 20px;
    position: relative;
    .clientItem__icon {
        margin-right: 20px;
    }
    .clientItem__title {
        color: #333333;
        font-family: "PingFang TC";
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 16px;
        margin-bottom: 10px;
    }
    .clientItem__subTitle {
        color: #999999;
        font-family: "PingFang TC";
        font-size: 12px;
        letter-spacing: 0;
        line-height: 12px;
    }
    .clientItem__arrowIcon {
        width: 20px;
        height: 20px;
        margin-left: auto;
    }
}
</style>
