<template>
    <div class="header">
        <img :src="srcImg" class="header__img" alt="">
        <div class="header__container">
            <p class="header__container__title">{{ title }}</p>
            <p :class="[isApplication ? 'header__container__content--application' : '', 'header__container__content']">{{ content }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Header',
    props: {
        srcImg: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        content: {
            type: String,
            required: true
        }
    },
    computed: {
        isApplication() {
            if (this.$route.path === '/application') {
                return true
            } else {
                return false
            }
        }
    }
}
</script>

<style lang="scss">
@import '@@/theme';

.header {
    position: relative;
    .header__img {
        width: 100%;
        display: block;
    }
    .header__container {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        padding: 20px;
        .header__container__title {
            margin: 0 0 1rem;
            font-family: "PingFang TC";
            font-size: 1.25rem;
            font-weight: 600;
            color: $header-title-color;
        }
        .header__container__content {
            width: 70%;
            margin: 0;
            font-family: "PingFang TC";
            font-size: 0.875rem;
            font-weight: 600;
            color: $header-content-color;
            &.header__container__content--application {
                width: 55%;
            }
        }
    }
}
</style>
