import badgee from 'badgee'

const errorBadge = badgee.define('error', 'red')
const infoBadge = badgee.define('info', 'green')
const warnBadge = badgee.define('warn', 'orange')

export class Console {
  static info(text) {
    return infoBadge.log(text)
  }

  static warn(text) {
    return warnBadge.log(text)
  }

  static error(text) {
    return errorBadge.log(text)
  }
}
