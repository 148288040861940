<template>
    <div class="client">
        <Header
            :srcImg="require('@@/banner_client.png')"
            :title="translateLang(`brand_client_title`)"
            :content="translateLang(`brand_client_content`)"
        />
        <ClientList />
        <Helper :text="translateLang(`brand_client_helper`)" :title_modal="translateLang('client_helper_title')" type="client" />
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import ClientList from '@/components/ClientList.vue'
import Helper from '@/components/Helper.vue'
import { mapGetters } from 'vuex'

export default {
    name: 'Client',
    components: {
        Header,
        ClientList,
        Helper
    },
    computed: {
        ...mapGetters(['translateLang', 'client'])
    }
}
</script>

<style lang="scss">
.client {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
}
</style>
